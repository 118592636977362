import React, {useEffect} from "react"
import { useStaticQuery, graphql } from "gatsby"
import {
    HeroAngleRightImage as Hero,
    LogoCloudSimpleBranded as LogoCloud,
    FeatureAlternatingWithTestimonial as Feature,
    FeatureList as FeatureList,
    StatsCard as Stats,
    ContactSimpleTwoColumns as Contact
} from "@domenicosolazzo/site-designsystem"
import Layout from "../components/Layouts/Layout"

import { DataPrivacyPage as PageData } from "../data"

export default function PrivacyPage() {
    const {
      hero
    } = PageData
    useEffect(() => {
      const script = document.createElement('script');
    
      script.src = "https://cdn.iubenda.com/iubenda.js";
      script.async = true;
    
      document.body.appendChild(script);
    
      return () => {
        document.body.removeChild(script);
      }
    }, []);
    
    return(
        <Layout>
          <Hero {...hero}/>
          
        </Layout>
    )
}